import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="student--course-test"
export default class extends Controller {
  connect() {
    // Get the view for the test
    if (!window.location.pathname.includes('/live_course/')) {
      openTest()
    } else {
      $(document).on('openTest', function () {
        $('#openTestBtn').addClass('blue-btn').removeClass('grey-btn disabled')
        $('#openTestBtn').off('click').on('click', openTest)
      })
    }

    $('#openTestBtn.disabled').on('click', (e) => {
      flash('alert', e.target.dataset.message)
    })

    function collectAnswers() {
      $('.answer-checkbox:visible:checked').each(function () {
        window.user_choices['user_test_score_result']['test_score_answer_ids'].push(
          $(this).attr('id').replace('answer_', '')
        )
      })
    }

    function initViewAndVariables() {
      const $question_container = $('.questions_container')
      setTimeout(() => {
        document.querySelector('.test_container')?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 200)

      window.question_counter = 0
      window.max_counter = $question_container.data('max-index')
      window.user_choices = {
        user_test_score_result: {
          in_app_purchase_subject_id: $question_container.attr('data-subject-id'),
          test_score_answer_ids: [],
          question_order: [...document.querySelectorAll('.question-order')].map((el) => el.value),
        },
      }

      $('#dialog-modal_test_results').iziModal()
    }

    function setClickAndEventHandlers() {
      $('#next_question').on('click', function () {
        window.question_counter += 1
        collectAnswers()
        if (window.question_counter < window.max_counter) {
          showNextQuestion()
        } else {
          ajaxPost(window.user_choices, '/student/save_course_test')
        }
      })

      $('#new_test').on('click', openTest)
    }

    function openTest() {
      ajaxGet($('.test_container').data('test-url'))
    }

    function ajaxPost(dataPost, url) {
      $.ajax({
        type: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        },
        url: url,
        dataType: 'html',
        data: dataPost,
        success: function (data) {
          $('#modal_score_container').html(data)
          $('#dialog-modal_test_results').iziModal('open')
          $('#new_test').css('display', 'inline')
          $('#next_question').css('display', 'none')
          $('.show-other').click(function () {
            var elem = $(this).closest('.answer-section')
            elem.fadeOut(300)
            $('.answer-section').not(elem).delay(300).fadeIn(300)
          })
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        },
      })
    }

    function ajaxGet(url) {
      loadingSpinner(true)

      $.ajax({
        type: 'GET',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        },
        url: url,
        dataType: 'html',
        success: function (data) {
          setTimeout(function () {
            $('.test_container').html(data)
            initViewAndVariables()
            setClickAndEventHandlers()
          }, 1500)
        },
        error: function (jqXHR, textStatus, errorThrown) {
          alert(jqXHR.responseText)
        },
      })

      loadingSpinner(false)
    }

    function showNextQuestion() {
      var current_question_id = parseInt($('.question_container:visible').attr('id').replace('question_', ''))
      var next_question = current_question_id + 1
      $('#question_' + current_question_id).css('display', 'none')
      $('#question_' + next_question).css('display', 'inline-block')
      $('#question_image_' + current_question_id).css('display', 'none')
      $('#question_image_' + next_question).css('display', 'inline-block')
    }
  }
}
